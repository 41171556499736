import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';
import { MEMO_TEMPLATES } from '../utils/memos.constant';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
  ) {}

  getDropdown(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem[];
    }>(API_URL.dropdown, params);
  }

  getDropdownWithHeader(header, params) {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  memoTypeItem() {
    return [
      ...MEMO_TEMPLATES.map((template) => {
        return {
          label: template.label,
          value: template.value,
          context: {
            display_name: template.label,
            display_name_en: template.label_en,
          },
        };
      }),
      {
        label: 'Upload',
        value: 'upload',
        context: {
          display_name: 'เอกสารอัปโหลด',
          display_name_en: 'Upload',
        },
      },
    ];
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
