export const DOCUMENT_PERMISSION_LIMIT = [
  {
    label:
      'People in LOA, CC, and department are allowed to view this document',
    label_th:
      'ให้ผู้ที่อยู่ในสายอนุมัติ สำเนาถึง และแผนกเข้าถึงเอกสารนี้ได้',
    value: false,
  },
  {
    label:
      'Only people in LOA and CC are allowed to view this document',
    label_th:
      'ให้ผู้ที่อยู่ในสายอนุมัติ และสำเนาถึง เข้าถึงเอกสารนี้ได้เท่านั้น',
    value: true,
  },
];

export const MEMO_NUMBER_LIST = [
  {
    name: 'Auto-Generated',
    id: false,
  },
  {
    name: 'Custom Memo Number',
    id: true,
  },
];

export const LOA_SIGN_USER_CHOICES = [
  { value: 'user', label_en: 'user', label: 'ผู้ที่อยู่ในระบบ' },
  {
    value: 'none user',
    label_en: 'non-user',
    label: 'ผู้ที่ไม่อยู่ในระบบ',
  },
];

export const LOA_SIGN_NONE_USER_PLACEHOLDER = {
  name: 'Contact Name',
  job_position: 'Job Position',
  email: 'Email Address',
  phone_number: 'Phone Number',
};

export const PRE_POSITIONS: Array<SignaturePosition> = [
  {
    sequence: 0,
    name: 'watermark',
    positions: null,
    type: 'watermark',
  },
  {
    sequence: 0,
    name: 'date',
    positions: null,
    type: 'date',
  },
  {
    sequence: 1,
    name: 'memo number',
    positions: null,
    type: 'memo_number',
  },
];
export const CHARACTER_LIMIT = {
  littleLimit: 20,
  shortLimit: 60,
  longLimit: 200,
  textLimit: 250,
};

export interface SignaturePosition {
  sequence: number;
  name: string;
  positions: Array<SingleSignaturePosition>;
  type?: string;
}

export interface SingleSignaturePosition {
  page: number;
  X: number;
  Y: number;
  id?: string;
  H?: number;
  W?: number;
  string?: string;
  fontSize?: number;
  fontStyle?: { name: string; value: string };
  initialPos?: { x: number; y: number };
  color?: string;
  colorForRender?: { r: number; g: number; b: number };
  dateFormat?: string;
  enableSetting: boolean;
  pageList: number[];
  pageIdList?: string[];
}

export const MEMO_TEMPLATES = [
  {
    label: 'แบบขออนุมัติภายใน',
    label_en: 'Internal',
    value: 'internal',
  },
  {
    label: 'แบบขออนุมัติภายนอก',
    label_en: 'External',
    value: 'external',
  },
  {
    label: 'แบบขออนุมัติการซื้อ',
    label_en: 'Purchase Request',
    value: 'purchase_request',
  },
  // {
  //   label: 'คำขอกู้เงินสามัญ',
  //   label_en: 'Ordinary Loan Request',
  //   value: 'form_l10',
  // },
  // {
  //   label: 'หนังสือสัญญากู้สำหรับกู้เงินสามัญ',
  //   label_en: 'Loan Agreement For Ordinary Loan',
  //   value: 'form_l11',
  // },
  // {
  //   label: 'หนังสือค้ำประกันสำหรับเงินกู้สามัญ',
  //   label_en: 'Letter of Guarantee for Ordinary Loan',
  //   value: 'form_l12',
  // },
  // {
  //   label:
  //     'หนังสือให้ความยินยอมหักเงินจากบัญชีเงินฝากธนาคารเพื่อชำระหนี้',
  //   label_en: 'Letter of Consent to Deduct Money from the Account',
  //   value: 'form_l13',
  // },
  // {
  //   label: 'หนังสือยินยอมให้หักเงินเดือนและเงินได้อื่นจากราชการ',
  //   label_en:
  //     'Letter of Consent to Deduct Salary and Other Income from Government Service',
  //   value: 'form_l14',
  // },
  // {
  //   label: 'คำขอกู้เงินกู้สามัญรวมหนี้',
  //   label_en: 'Application for General Loan',
  //   value: 'form_l20',
  // },
  // {
  //   label: 'หนังสือสัญญากู้สำหรับเงินกู้สามัญรวมหนี้',
  //   label_en: 'Loan Agreement for Debt Consolidation Ordinary Loan',
  //   value: 'form_l21',
  // },
  // {
  //   label:
  //     'หนังสือค้ำประกันสำหรับเงินกู้สามัญรวมหนี้เพื่อพัฒนาคุณภาพชีวิตของสมาชิกสหกรณ์',
  //   label_en:
  //     'Letter of guarantee for ordinary loans, debt consolidation to improve the quality of life of cooperative members',
  //   value: 'form_l22',
  // },
  // {
  //   label: 'หนังสือต่อท้ายสัญญาสามัญรวมหนี้ ',
  //   label_en: 'Attachment letter to the general contract',
  //   value: 'form_l25',
  // },
  // {
  //   label: 'คำขอกู้เงินกู้สามัญ ATM (หนี้ในอนาคต)',
  //   label_en: 'Ordinary ATM Loan Request (Future Debt)',
  //   value: 'form_l30',
  // },
  // {
  //   label: 'หนังสือกู้เงินสามัญ ATM (หนี้ในอนาคต) ',
  //   label_en: 'Ordinary ATM Loan Letter (Future Debt)',
  //   value: 'form_l31',
  // },
  // {
  //   label: 'หนังสือค้ำประกันกู้เงินสามัญ ATM (หนี้ในอนาคต)',
  //   label_en:
  //     'Letter of guarantee for ordinary loan ATM (Future Debt)',
  //   value: 'form_l32',
  // },
  // {
  //   label: 'หนังสือต่อท้ายสัญญาสามัญ ATM (หนี้ในอนาคต)',
  //   label_en: 'Addendum to ordinary ATM contract (Future Debt)',
  //   value: 'form_l35',
  // },
  // {
  //   label:
  //     'คำขอกู้เงินสามัญเพื่อแก้ไขปัญหาหนี้สินและส่งเสริมวิทยฐานะ(วฐ)',
  //   label_en:
  //     'Request for ordinary loan to solve debt problems and promote academic standing',
  //   value: 'form_l40',
  // },
  // {
  //   label:
  //     'หนังสือกู้เงินเพื่อแก้ไขปัญหาหนี้สินและส่งเสริมวิทยฐานะ(วฐ)',
  //   label_en: 'Loan Agreement for Loan to Solve Debt Problems',
  //   value: 'form_l41',
  // },
  // {
  //   label:
  //     'หนังสือค้ำประกันสำหรับเงินกู้สามัญเพื่อแก้ไขปัญหาหนี้สินและส่งเสริมวิทยฐานะ (วฐ )ของสมาชิกสหกรณ์',
  //   label_en:
  //     'Letter of guarantee for ordinary loans to solve debt problems and promote cooperative members',
  //   value: 'form_l42',
  // },
  // {
  //   label: 'คำขอกู้เงินเพื่อเหตุฉุกเฉินปันสุข',
  //   label_en: 'Loan request for Pansuk emergency',
  //   value: 'form_l50',
  // },
  // {
  //   label: 'หนังสือกู้เงินเพื่อเหตุฉุกเฉินปันสุข',
  //   label_en: 'Sliver Loan request for Pansuk emergency',
  //   value: 'form_l51',
  // },
  // {
  //   label: ' คำขอกู้เงินสามัญเพื่อการศึกษา(สศ)',
  //   label_en: 'Application for an ordinary loan for education',
  //   value: 'form_l60',
  // },
  // {
  //   label: 'หนังสือกู้สำหรับเงินกู้สามัญเพื่อการศึกษา',
  //   label_en: 'Loan Agreement for an Ordinary Loan to Education',
  //   value: 'form_l61',
  // },
  // {
  //   label: ' หนังสือค้ำประกันสำหรับเงินกู้สามัญเพื่อการศึกษา(สศ )',
  //   label_en: 'Letter of Guarantee for Ordinary Student Loan',
  //   value: 'form_l62',
  // },
  // {
  //   label: 'หนังสือต่อท้ายสัญญาเงินกู้สามัญเพื่อการศึกษา',
  //   label_en:
  //     'Attachment letter to the general loan agreement for education',
  //   value: 'form_l65',
  // },
];
